import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "./components/layout"
import ProjectSection from "./components/project";
import { wrap } from "module";


const Resume = () => {
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };
  return (
    <Layout pageTitle="Resume">
      <button className="primaryButton primaryButton--right" onClick={handleClick}>{`Display Resume as ${isActive ? 'Text' : 'Image'}`}</button>
      <StaticImage
        className={`resume ${isActive ? 'visible' : ''}`}
        src="../assets/resume.png"
        alt="Oreoluwa Akinsanmi Resume"
        layout="constrained"
      />

      <div className={`content content--grid-sidebar resume${isActive ? '' : 'visible'}`} style={{ display: 'flex', marginTop: '68px' }}>
        <aside style={{ padding: '0 10px 0 5px' }}>
          <h3>Contact</h3>
          <p>create@remokingdom.com</p>
          <p>linkedin.com/in/ore-akinsanmi</p>
          <p>US Eastern Time Zone</p>


          <h3>Bio</h3>
          <p>Passionate about empowering artists, small business owners and local community groups through user-centric web design. As a creative
            technologist I leverage JavaScript, HTML
            and CSS based
            frameworks or platforms (CMS included) to create exceptional user experiences.
            My proven track record demonstrates a talent for collaborating effectively with
            stakeholders and cross-
            functional teams.</p><br />

          <h3>Technical Expertise</h3>
          <p>
          Squarespace<br />
Cargo.site<br />
Wix<br />
Headless CMS<br />
Domain Name Registration<br />
DNS setup<br />
Figma<br />
            HTML & CSS<br />
            User-centered Design<br />
Wireframe & Prototyping<br />
            React<br />
            Javascript<br />
            Agile Methodology<br />
            Jira<br />
            Next.js<br />
            Project Management</p><br />

          <h3>Education</h3>
          <p>User Experience (UX) Design <br />
            Google Certificate Course</p>
          <p>Chemical Engineering B.S.<br />
            Howard University Washington, DC</p><br />
        </aside>
        <main style={{ flex: '3 0 68%', paddingLeft: '10px' }}>
          <ProjectSection
            projectName={'BFSFF'}
            projectTitle={'WEBSITE REDESIGN & MIGRATION SPECIALIST (CONTRACT)'}
            projectLink={'https://www.bfsfilmfest.com'}
            projectDate={'Feb. - Aug. 2023 (Remote)'}
            clientRollOrName={'Black Femme Supremacy Film Fest'}
            tech1={'Wordpress to Squarespace Website Migration & Redesign'}
            projectDescription={['Defined migration and design roadmap in collaboration with stakeholders to create a roadmap for a seamless six-month transition with consistent brand identity.',
              'Designed reusable components, standardized themes, and functionality across 20+ pages for optimal user experience and cohesiveness which increased user traffic by 50%.',
              'Improved web accessibility via WCAG-compliant font size, color palettes, and metadata adjustments with HTML and CSS.']}
          />
           <ProjectSection
            projectName={'EUPHORM'}
            projectTitle={'NETWORKING AND WEB SECURITY CONSULTANT (FREELANCE)'}
            projectDate={'2023 (remote)'}
            clientRollOrName={'Euphorm Clothing Company'}
            projectDescription={['Provided reccomendations for Domain Name registration platforms that prioritize network security','DNS setup/configuration on Shopify CMS', 'Configured SPF and DMARC records to support new email authentication requirements by Google']}
            tech1={undefined}
            projectLink={'https://euphorm.co'} />
           <ProjectSection
            projectName={'ALXV'}
            projectTitle={'USER EXPERIENCE DESIGNER (CONTRACT)'}
            projectDate={'Feb. 2022 (hybrid)'}
            clientRollOrName={'Alx Velozo'}
            projectDescription={['Led migration of website from raw HTML pages to a user-friendly CMS (Cargo.site). Imported and rewrote custom HTML and CSS as needed',
            'Collaborated closely with client throughout the process to ensure new website maintained desired visual identity while enhancing user experience through improved navigation and functionality',
            'Registered new Domain name and configured DNS']}
            tech1={undefined}
            projectLink={'https://alxvelozo.com'} />
           <ProjectSection
            projectName={'IMSLBB'}
            projectTitle={'WEB DEVELOPER (FREELANCE)'}
            projectDate={'Apr. 2021 (remote)'}
            clientRollOrName={'IMsLBB'}
            projectDescription={['Crafted a custom node.js script for generating unique web tokens to integrate with a third-party video conferencing platform API, effectively tracking members on Wix CMS for the IMsLBB community']}
            tech1={undefined}
            projectLink={'https://www.imslbb.org'} />
          <ProjectSection
            projectName={'LEAFLY'}
            projectTitle={'UX/UI DEVELOPER (FULL-TIME)'}
            projectLink={'https://www.leafly.com/dispensary-info/storehouse/menu'}
            projectDate={'2018 - 2020 (Hybrid)'}
            clientRollOrName={'LEAFLY'}
            tech1={'Technology Stack - Express, NextJs, React'}
            projectDescription={['Transformed Figma wireframes & prototypes into fully realized, responsive mobile-first user interfaces for inventory management software, consumer retail menus, and business account settings.',
              'Collaborated with visual designer, back-end developers and dev-ops to improve profile manager interface usability for vendors which resulted in a 30% increase in user satisfaction',
              'Developed and implemented reusable UI components for a centralized internal library, ensuring a consistent experience across multiple Node.js applications.']} />
         
        </main>
      </div>
    </Layout>)
};
export default Resume;
/*<a
       className="link"
       href="src/assets/oreoluwa_akinsanmi_res_web.pdf"
       target="_blank"
       rel="noopener"
     >Download or Print PDF</a>*/